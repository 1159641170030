<template>
  <a-card :bordered="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商名称">
              <a-input v-model="searchData.name" placeholder="请输入经销商名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商编码">
              <a-input v-model="searchData.dealerCode" placeholder="请输入经销商编码" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <!--        <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
          <!--          <a-form-model-item label="大区事务所">-->
          <!--            <a-cascader-->
          <!--              :options="dealerList"-->
          <!--              v-model="regionalOffice"-->
          <!--              change-on-select-->
          <!--              placeholder="请选择大区/事务所"-->
          <!--              @change="onChange"-->
          <!--              :field-names="{ label: 'name', value: 'id', children: 'children' }"-->
          <!--            />-->
          <!--          </a-form-model-item>-->
          <!--        </a-col>-->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商类型">
              <DictSelect
                field="dealerType"
                :value.sync="searchData.dealerType"
                style="width: 100%"
                placeholder="经销商类型"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="审核状态">
              <DictSelect field="verifyStatus" :value.sync="searchData.verifyStatus" style="width: 100%"
                          placeholder="审核状态"></DictSelect>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="合作状态">
              <DictSelect field="cooperateStatus" :value.sync="searchData.cooperateStatus" style="width: 100%" placeholder="生成方式"></DictSelect>
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="帐号">
              <a-input v-model="searchData.account" placeholder="请输入帐号" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isCusInfoAdd">新增</a-button>
        <a-button
          type="primary"
          v-if="selectedRowKeys.length >= 1 && selectedRows[0].verifyStatus == 0 && isCusInfoEdit"
          @click="toHandler('edit')"
          icon="edit"
        >编辑
        </a-button
        >
        <a-button type="primary" v-if="selectedRowKeys.length >= 1 && isCusInfoDel" @click="toHandler('del')" icon="delete">删除</a-button>
        <a-button
          type="primary"
          v-if="selectedRowKeys.length >= 1 && isCusInfoSelect"
          @click="toHandler('check')"
          icon="eye"
        >查看
        </a-button
        >
        <a-button v-if="isShare && selectedRowKeys.length > 0 && selectedRows[0].verifyStatus == 2" type="primary" @click="toHandler('share')" icon="branches">共享
        </a-button>
        <a-button
          v-if="isTypeCharge && selectedRowKeys.length > 0  && selectedRows[0].verifyStatus == 2 && selectedRows[0].dealerClassify == 1"
          type="primary"
          @click="typeChargeApply"
          icon="sync"
        >类型转换</a-button>
        <a-button type="primary"
                  v-if="isSubmit && selectedRowKeys.length > 0 && selectedRows[0].verifyStatus == 0"
                  @click="onSubmitAudit(0)"
                  icon="form">提交审核
        </a-button>
        <a-button
          v-if="isBack && selectedRowKeys.length > 0 && selectedRows[0].verifyStatus == 1"
          type="primary"
          @click="toHandler('rollbackStatus')"
          icon="rollback"
        >撤审
        </a-button>
        <a-button
          v-if="isFs && selectedRowKeys.length > 0 && item[0].verifyStatus == 2"
          type="primary"
          @click="toHandler('fanShenStatus')"
          icon="rollback"
        >反审</a-button>
        <a-button type="default" v-if="selectedRowKeys.length >= 1 && isCusInfoPwd" @click="onPassword()">重置密码
        </a-button
        >
        <a-button type="default" v-if="selectedRowKeys.length >= 1 && isUpdateContact" @click="toHandler('contact')">修改联系人
        </a-button
        >
        <a-button type="default" v-if="selectedRowKeys.length >= 1 && isUpdateAddress" @click="toHandler('address')">修改收货地址
        </a-button
        >
        <a-button type="default" v-if="selectedRowKeys.length > 0 && isEditManager" @click="handleManagerName">修改担当</a-button>
        <a-button type="default" v-if="selectedRowKeys.length == 1 && isEditManagerNum" @click="handleManagerNum">修改员工数量</a-button>
        <a-button type="default" v-if="selectedRowKeys.length == 1 && isCommunitySet" @click="handleCommunitySet">配置小区权限</a-button>
        <!--      <a-button type="default" v-if="selectedRowKeys.length >= 1 && isInvoice" @click="toHandler('invoice')">修改发票</a-button>-->
        <downLoad v-if="isExport" method="get" api="/api/dealer/dealer/dealerInfo/excelDealerInfo" :params="searchData" name="客户资料.xls">导出经销商资料</downLoad>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :rowClassName="rowClassName"
         :customRow="changeTableRow"
      >
        <span slot="dealerType" slot-scope="text">
          <a-tag v-if="text == 1">中心店</a-tag>
          <a-tag v-if="text == 2">核心店</a-tag>
          <a-tag v-if="text == 3">一般店</a-tag>
          <a-tag v-if="text == 4">核心店（预备）</a-tag>
        </span>
<!--        <span slot="cooperateStatus" slot-scope="text">-->
<!--          <a-tag v-if="text == 1" color="#87d068">合作中</a-tag>-->
<!--          <a-tag v-if="text == 2">合作终止</a-tag>-->
<!--        </span>-->
        <span slot="verifyStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#333333">草稿</a-tag>
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
        </span>
        <span slot="numberOfEmployees" slot-scope="text,row">
         {{row.haveStaffNum}} / {{row.numberOfEmployees}}
        </span>
        <span slot="shareFirmList" slot-scope="text">
        <label v-if="text == null">未共享</label>
          <!--        <a-tooltip placement="left" title="getFirmInfo" :get-popup-container="getPopupContainer">-->
          <label v-if="text != null">
            <label style="color: red" v-bind:key="item.firmName" v-for="item in text" >【{{item.areaName}}】{{item.firmName}} <br/></label>
          </label>
          <!--        </a-tooltip>-->
      </span>

      </a-table>
      <!-- 编辑表单 -->
      <CustomerInfo-edit-modal ref="CustomerInfoEditModal" @reload="getData"></CustomerInfo-edit-modal>
      <CustomerInfoCheckModal ref="CustomerInfoCheckModal" @reload="getData"></CustomerInfoCheckModal>
       <CustomerContactsModal ref="CustomerContactsModal" @reload="getData"></CustomerContactsModal>
      <!--查看-->
      <Password ref="password"></Password>
      <ShareFirmList ref="ShareFirmList" @reload="getData"></ShareFirmList>

      <a-modal v-model="visibleCharge" title="类型转换申请" @ok="handleOkCharge"  @cancel="handleCanceCharge" :maskClosable="false" width="40%" :confirm-loading="confirmLoading">
        <a-form-model ref="formCharge" :model="rowDataCharge" :rules="formRule" :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="权限组" prop="applyTemplateId">
          <a-select placeholder="请选择权限组" v-model="rowDataCharge.applyTemplateId">
            <a-select-option :value="item.id" v-for="item in templateList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="rowDataCharge.remarkCharge"></a-textarea>
        </a-form-model-item>
      </a-form-model>
      </a-modal>

      <a-modal
        title="修改担当"
        :visible="visible"
        width="40%"
        :confirm-loading="confirmLoading"
        @ok="handleOkManagerName"
        @cancel="handleCancelManagerName"
      >
        <a-form-model>
          <a-form-model-item label="修改担当员工" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select v-model="managerName" style="width: 70%" show-search>
              <a-select-option :value="sitem.name" v-for="sitem in managerNameList" :key="sitem.id">
                {{ sitem.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <a-modal
        title="修改员工数量"
        :visible="visibleNum"
        width="40%"
        :confirm-loading="confirmLoadingNum"
        @ok="handleOkManagerNum"
        @cancel="handleCancelManagerNum"
      >
        <a-form-model>
          <a-form-model-item label="修改员工数量" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input-number :min="1" v-model="managerNum" placeholder="员工数量"></a-input-number>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <a-modal
        title="配置小区操作权限"
        :visible="visibleSet"
        width="50%"
        :confirm-loading="confirmLoadingSet"
        @ok="handleOkCommunitySet"
        @cancel="handleCancelCommunitySet"
      >
        <a-form-model>
          <a-form-model-item label="是否开放操作" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-switch
              v-model="flagSet"
              checked-children="是"
              un-checked-children="否"
              default-checked
            />
          </a-form-model-item>
          <a-form-model-item label="可操作时间段" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" v-if="flagSet == true">
<!--            <DateRange :startTime.sync="startTime" :endTime.sync="endTime"></DateRange>-->
            <TimePicker @timeSelect="timeSelect" :startTime="startTime" :endTime="endTime"></TimePicker>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import Password from './components/password'
import {columns} from './components/colums.js'
import CustomerInfoEditModal from './components/CustomerInfoEditModal.vue'
import CustomerContactsModal from './components/CustomerContactsModal.vue'
import CustomerInfoCheckModal from './components/CustomerInfoCheckModal.vue'
import ShareFirmList from './components/ShareFirmList.vue'
import {delCustomerInfo, listCustomerInfo, rollbackStatus,fanShenStatus, selectByIdCustomerInfo, addTypeApplyInfo} from './api/CustomerInfoApi'
import {checkPermission} from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'customerInfo',
  components: {
    CustomerInfoEditModal,
    Password,
    ShareFirmList,
    CustomerInfoCheckModal,
    CustomerContactsModal
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      managerName: '',
      managerNameList: [],
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: {y: 600},
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      statusList: [],
      item: [],
      isCusInfoDel: checkPermission('cus:info:del'),
      isCusInfoAdd: checkPermission('cus:info:add'),
      isCusInfoEdit: checkPermission('cus:info:edit'),
      isCusInfoSelect: checkPermission('cus:info:select'),
      isCusInfoPwd: checkPermission('cus:info:pwd'),
      isUpdateContact: checkPermission('cus:info:updateContact'),
      isUpdateAddress: checkPermission('cus:info:updateAddress'),
      isEditManager: checkPermission('cus:info:edit_manager'),
      isEditManagerNum: checkPermission('cus:info:edit_manager_num'),
      isCommunitySet: checkPermission('cus:info:community_set'),
      isSubmit: checkPermission('cus:info:submit'),
      isBack: checkPermission('cus:info:back'),
      isFs: checkPermission('cus:info:fanShen'),
      isShare: checkPermission('cus:info:share'),
      isExport: checkPermission('cus:info:export'),
      isTypeCharge: checkPermission('cus:info:typeCharge'),
      status: [
        {
          key: ' ',
          label: '全部',
        },
        {
          key: true,
          label: '正常',
        },
        {
          key: false,
          label: '冻结',
        },
      ],
      dealerList: [],
      regionalOffice: [],
      visibleSet: false,
      confirmLoadingSet: false,
      flagSet: false,
      startTime: '',
      endTime: '',
      visibleCharge: false,
      applyTemplate:'',
      remarkCharge:'',
      templateList:[],
      rowDataCharge:{},
       formRule: {
        applyTemplateId:[{ required: true, message: '请选择权限组', trigger: 'change' }],
      },
      visibleNum: false,
      confirmLoadingNum: false,
      managerNum: ''
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        //fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
          _this.item = selectedRows
        },
      }
    },
  },
  methods: {
    timeSelect(row){
      this.startTime = row.startTime
      this.endTime = row.endTime
    },
    handleCommunitySet() {

      this.axios.get('/api/dealer/dealer/dealerInfo/selectCommunitySetByDealerId/'+this.selectedRows[0].id).then((res) => {
        // 获取经销商操作小区配置
        if(res.body.id != null){
          this.flagSet = res.body.flagSet
          this.startTime = res.body.startTime
          this.endTime = res.body.endTime
        }
      })
      this.visibleSet = true
    },
    handleOkCommunitySet() {
       if(this.flagSet){
        if(!this.startTime){
          return this.$message.warning('请选择操作时间段')
        }
      }
      this.confirmLoadingSet = true
      let params = {
        dealerId: this.selectedRows[0].id,
        flagSet: this.flagSet,
        startTime: this.startTime,
        endTime: this.endTime,
      }
      this.axios.post('/api/dealer/dealer/dealerInfo/updateDealerCommunitySet', params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visibleSet = false
          this.flagSet = false
          this.startTime = ''
          this.endTime = ''
          this.getData()
        }
      })
        .catch(err => {
        })
        .finally(() => {
          this.confirmLoadingSet = false
        })
    },
    handleCancelCommunitySet() {
      this.visibleSet = false
      this.confirmLoadingSet = false
      this.flagSet = false
      this.startTime = ''
      this.endTime = ''
    },
    handleManagerName() {
      this.visible = true
      this.managerName = this.selectedRows[0].managerName
      // console.log(this.selectedRows)
    },
    handleManagerNum() {
      this.visibleNum = true
      this.managerNum = this.selectedRows[0].numberOfEmployees
      console.log(this.selectedRows[0])
    },
    handleOkManagerName() {
      this.confirmLoading = true
      let params = {
        dealerId: this.selectedRowKeys[0],
        managerName: this.managerName,
      }
      let managerNameItem = this.managerNameList.filter(item => item.name === this.managerName)
      params.managerId = managerNameItem[0].id
      this.axios.post('/api/dealer/dealer/dealerInfo/editManagerDealer', params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.managerName = ''
          this.getData()
        }
      })
        .catch(err => {
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    handleCancelManagerName() {
      this.visible = false
      this.confirmLoading = false
      this.managerName = ''
    },
    handleOkManagerNum() {
      this.confirmLoadingNum = true
      let params = {
        dealerId: this.selectedRowKeys[0],
        managerNum: this.managerNum,
      }
      this.axios.post('/api/dealer/dealer/dealerInfo/editManagerNumDealer', params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visibleNum = false
          this.managerNum = ''
          this.getData()
        }
      })
        .catch(err => {
        })
        .finally(() => {
          this.confirmLoadingNum = false
        })
    },
    handleCancelManagerNum() {
      this.visibleNum = false
      this.confirmLoadingNum = false
      this.managerNum = ''
    },
    // 获取担当数据
    getManagerName() {
      this.axios.get('/api/base/system/firm/manager/listManagerCurrentFirm').then(res => {
        this.managerNameList = res.body
      })
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      //此页面只查询订单经销商
      this.searchData.dealerClassify = 1
      listCustomerInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const {total, records} = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      // this.axios.get('/api/firm/mhiac/mhiacArea/listAll').then((res) => {
      //   // 获取大区事务所经销商数据
      //   this.dealerList = res.body
      // })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.item = selectedRows
    },
    // 提交审核
    onSubmitAudit(value) {
      let list = []
      if (this.selectedRows.length === 0) {
        this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      if (value == 0) {
        this.selectedRows.filter((e) => {
          list.push({
            id: e.id,
            verifyStatus: 1,
            verifyRemark: this.verifyRemark
          })
        })
      }
      this.axios.post('/api/dealer/dealer/dealerInfo/updateStatus/' + list[0].id).then((res) => {
        if (res.code == 200) {
          this.rejectReason = ''
          this.$notification.success({message: res.message})
          this.visible = false
          this.getData()
        } else {
          this.$notification.error({message: res.message})
          this.getData()
        }
      })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.getData()
    },

    onChange(val) {
      this.searchData.bigAreaId = val[0]
      this.searchData.firmId = val[1]
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1
      this.page.pageSize = 10
      // this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    handleChange(value) {
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    onPassword() {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      _this.$refs.password.onsetRowData(_this.selectedRows[0])
    },
    toHandler(name) {
      const _this = this
      if (name === 'share') {
        _this.$refs.ShareFirmList.isShow(_this.selectedRows[0], 'share')
        return
      }
      if (name === 'add') {
        _this.$refs.CustomerInfoEditModal.setRowData({}, '1', 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.selectedRows[0], '1', 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delCustomerInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.getData()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            },
          })
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.getData()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            },
          })
          break
        case 'fanShenStatus':
          _this.$confirm({
            title: '反审警告',
            content: '确认要撤销此条记录的审核记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await fanShenStatus(_this.item[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.CustomerInfoCheckModal.setRowData(_this.selectedRows[0], 'check')
          break
        case 'contact':
          _this.$refs.CustomerContactsModal.setRowData(_this.selectedRows[0], '1', 'edit', '2')
          break
        case 'address':
          _this.$refs.CustomerContactsModal.setRowData(_this.selectedRows[0], '1', 'edit', '3')
          break
        case 'invoice':
          _this.$refs.CustomerContactsModal.setRowData(_this.selectedRows[0], '1', 'edit', '4')
          break
      }
    },

    // 类型转换申请
    typeChargeApply() {
      let _this=this;
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      // 排除现有经销商的角色模板数据
      this.axios.get('/api/base/dealer/dealerTemplate/listAllByType/1').then((res) => {
        this.templateList = res.body
      })
      _this.visibleCharge = true;
    },

      handleOkCharge(){
      this.$refs.formCharge.validate(async (valid) => {
        if (!valid) {
          return
        }
      this.confirmLoading = true
      let dealerIds = []
      console.log("this.item",this.item)
      this.item.filter((e) => {
        dealerIds.push(e.id)
      })
       let templateType = this.templateList.filter(item=>{
         return item.id === this.rowDataCharge.applyTemplateId
       })
      const params = {
        applyClassify: templateType[0].templateType,
        applyTemplate: templateType[0].id,
        remark: this.rowDataCharge.remarkCharge,
        dealerIds: dealerIds
      }
      addTypeApplyInfo(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visibleCharge = false
          this.getData()
        }
      }).finally(() => {
        this.confirmLoading = false
        this.visibleCharge = false
        this.applyTemplate = ''
        this.remarkCharge = ''
      })
      })
    },
    handleCanceCharge(){
      this.confirmLoading = false
      this.visibleCharge = false
      this.applyTemplate = ''
      this.remarkCharge = ''
      this.rowDataCharge= {}
    },

  },
  created() {
    this.getManagerName()
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}

::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}

::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
